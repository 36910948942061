import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '../interfaces/api-response.type';
import { map } from 'rxjs/operators';

@Injectable()
export class InstructionService {

    public API_URL = '/instructions';

    constructor(private http: HttpClient) {}

    // public getInstructions(): any {
    //     return this.http.get<ApiResponse>(
    //         this.INSTRUCTIONS_LIST_API_URL)
    //     .pipe(map(instructions => {
    //         return instructions;
    //     }));
    // }

    public save(data: any): any {
        return this.http.put<ApiResponse>(
            this.API_URL + '/' + data._id, data)
        .pipe(map(response => {
            return response;
        }));
    }

    public saveAs(data: any): any {
        return this.http.post<ApiResponse>(
            this.API_URL, data)
        .pipe(map(response => {
            return response;
        }));
    }

    public getInstructions(): any {
        return this.http.get<ApiResponse>(
            this.API_URL)
        .pipe(map(instructions => {
            instructions.data.map(instruction => {
                instruction['view'] = {};
                instruction.properties.forEach(element => {
                    instruction.view[element.name] = element.value;
                });
            })
            return instructions;
        }));
    }    

}