import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { EmptyLayoutComponent } from 'app/layout/layouts/empty/empty.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: 'app/rates'},

    // Redirect signed in user to the '/example'
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'app/rates'},

    // Auth routes
    {
        path: 'app',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: EmptyLayoutComponent,
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            // {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)}
        ]
    },
    {
        path: 'app',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: EmptyLayoutComponent,
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)}
        ]
    },

    // Landing routes
    {
        path: 'app',
        component: EmptyLayoutComponent,
        children   : [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)},
        ]
    },

    // app routes
    {
        path: 'app',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],        
        component  : LayoutComponent,  
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [
            {path: 'rates', redirectTo: 'rates/', pathMatch: 'full'},
            {path: 'rates/:id', loadChildren: () => import('app/modules/rates/rates.module').then(m => m.RateModule)},
            {path: 'reports', loadChildren: () => import('app/modules/admin/apps/reports/reports.module').then(m => m.ReportsModule)},
            
        ]
    },

    {
        path: 'app/settings',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],           
        component  : LayoutComponent,  
        resolve    : {
            initialData: InitialDataResolver,
        },
        data: {
            authorities: ['admin','manager'],
        },
        children   : [
            {path: 'instructions', loadChildren: () => import('app/modules/instructions/instructions.module').then(m => m.InstructionModule)},
        ]
    },

    {
        path: 'app/admin',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],         
        component  : LayoutComponent,  
        resolve    : {
            initialData: InitialDataResolver,
        },
        data: {
            authorities: ['admin'],
        },        
        children   : [
            {path: 'users', loadChildren: () => import('app/modules/admin/apps/users/users.module').then(m => m.UsersModule)},
            {path: 'companies', loadChildren: () => import('app/modules/admin/apps/companies/companies.module').then(m => m.CompaniesModule)},
        ]
    },

    {
        path: '',
        component  : LayoutComponent,  
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [
            {path: 'mailbox', loadChildren: () => import('app/modules/admin/apps/mailbox/mailbox.module').then(m => m.MailboxModule)},
            {path: 'contacts', loadChildren: () => import('app/modules/admin/apps/contacts/contacts.module').then(m => m.ContactsModule)},
        ]
    },

    // // Reports routes
    // {
    //     path: '',
    //     component  : LayoutComponent,
    //     resolve    : {
    //         initialData: InitialDataResolver,
    //     },
    //     children   : [
    //         {path: 'app-reports', loadChildren: () => import('app/modules/reports/reports.module').then(m => m.ReportModule)}
    //     ]
    // },

    // Admin routes
    {
        path       : '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [

            // Example
            {path: 'example', loadChildren: () => import('app/modules/admin/example/example.module').then(m => m.ExampleModule)}

            // 404 & Catch all
            // {path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/admin/pages/errors/error-404/error-404.module').then(m => m.Error404Module)},
            // {path: '**', redirectTo: '404-not-found'}
        ]
    }
];
