/* tslint:disable:max-line-length */
import { TreoNavigationItem } from '@treo/components/navigation';

export let defaultNavigation: TreoNavigationItem[] = [  
    {
        id      : 'dashboard',
        title   : 'Dashboard',
        subtitle: 'Views',
        type    : 'group',
        icon    : 'apps',
        children: [
            {
                id   : 'rates',
                title: 'Rates',
                type : 'basic',
                link : '/app/rates',
                icon : 'heroicons:currency-dollar',
            },
            {
                id   : 'reports',
                title: 'Reports',
                type : 'basic',
                link : '/app/reports',
                icon : 'heroicons:document-report',
            }            
        ]
    },
];

export const compactNavigation: TreoNavigationItem[] = [
    {
        id      : 'starter',
        title   : 'Starter',
        type    : 'aside',
        icon    : 'apps',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];
export const futuristicNavigation: TreoNavigationItem[] = [
    {
        id   : 'starter.example',
        title: 'Example component',
        type : 'basic',
        icon : 'heroicons:chart-pie',
        link : '/example'
    },
    {
        id   : 'starter.dummy.1',
        title: 'Dummy menu item #1',
        icon : 'heroicons:calendar',
        type : 'basic'
    },
    {
        id   : 'starter.dummy.2',
        title: 'Dummy menu item #1',
        icon : 'heroicons:user-group',
        type : 'basic'
    }
];
export const horizontalNavigation: TreoNavigationItem[] = [
    {
        id      : 'starter',
        title   : 'Starter',
        type    : 'aside',
        icon    : 'apps',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];
