import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '../interfaces/api-response.type';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import * as groupBy  from 'lodash.groupby'
import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { CompanyEditComponent } from 'app/modules/admin/apps/companies/edit/edit.component';

@Injectable()
export class RateService {

    public RATES_LIST_API_URL = '/rates';

    constructor(private http: HttpClient, private _matDialog: MatDialog) {}

    public defaultcolumnDefinition = {
        resizable: true,
        filter: true,
        sortable: true
    };

    public columnDefinition = [
        {headerName: 'Site', field: 'Site', enableRowGroup: true, pivot: true },
        {headerName: 'Rate', field: 'Rate', enableRowGroup: true, type: 'numericColumn', aggFunc: this.rateAvg },        
        {headerName: 'Company', field: 'Company', enableRowGroup: true, pivot: true },
        {headerName: 'Utility', field: 'Utility', enableRowGroup: true },
        {headerName: 'State', field: 'State', enableRowGroup: true },
        {headerName: 'Zipcode', field: 'Zipcode', enableRowGroup: true },
        {headerName: 'Term', field: 'Term', enableRowGroup: true },
        {headerName: 'Type', field: 'ProductType', enableRowGroup: true },
        {headerName: 'Green', field: 'Green', enableRowGroup: true },
        {headerName: 'Class', field: 'Class', enableRowGroup: true },
        {headerName: 'Estimated', field: 'Estimated', enableRowGroup: true },
        {headerName: 'Territory', field: 'Territory', enableRowGroup: true },
        {headerName: 'Commodity', field: 'Commodity', enableRowGroup: true },
        {headerName: 'UoM', field: 'UoM', enableRowGroup: true },
        {headerName: 'Usage', field: 'Usage', enableRowGroup: true, type: 'numericColumn' },
        {headerName: 'Projected', field: 'isProjected', enableRowGroup: true },        
        {headerName: 'Previous', field: 'lastRate', enableRowGroup: true, type: 'numericColumn' },
        {headerName: 'Name', field: 'Name', enableRowGroup: true },
        {headerName: 'Date', field: 'Date', enableRowGroup: true },
        {headerName: 'PDF', field: 'PDF', onCellClicked: this.openDialog, cellRenderer: (params) => {
                if (params.data && params.data.PDF && params.data.PDF.length > 0){
                    return `
                        <mat-icon class="mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true" height="100%" width="100%">picture_as_pdf</mat-icon>
                    `
                } else {
                    return '';
                }
            }  
        },
        {headerName: 'Image', field: 'ScreenCapture', cellRenderer: (params) => {
                if (params.data && params.data.CompanyId && params.data.ScreenCapture){
                    return `<a href="http://aa3bc6dab2cd57d85e35-ad3c2f67fe765320f91a2c343be376b5.r1.cf1.rackcdn.com/${params.data.CompanyId}/${params.data.ScreenCapture}" target="_blank">
                    <mat-icon class="mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true" height="100%" width="100%">image_search</mat-icon>
                    </a>`
                } else {
                    return '';
                }
            }  
        }
    ];
    
    openDialog() {
        const dialogRef = this._matDialog.open(CompanyEditComponent, {
            panelClass: 'mailbox-compose-dialog',
            data: {}
        });

        // dialogRef.afterClosed()
        //          .subscribe(result => {
        //             this.companies.map((c, i) => {
        //                 if (c._id == company._id){
        //                     this.companies[i] = result;
        //                 }
        //             });  
        //             this.companies = [].concat(this.companies);         
        //             this._changeDetectorRef.markForCheck();
        //          });
      }

    public sideBar = {
        toolPanels: [
          {
            id: 'reports',
            labelDefault: 'Reports',
            labelKey: 'reports',
            iconKey: 'reports',
            toolPanel: 'reportsToolPanel',
          },            
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
    };

    public rateAvg(params): string {
        let result = 0;
        if (params.length === 0) return '';
        params.forEach((value) => {
            if (typeof value === 'number') {
                result += value;
            }
        });
        return (result / params.length).toFixed(4);
    } 

    public getRates(date: string): any {
        return this.http.get<ApiResponse>(
            this.RATES_LIST_API_URL + '/' + date)
        .pipe(map(rates => {
            return rates;
        }));
    }

    public getRatesForInstruction(id: string): any {
        return this.http.get<ApiResponse>(
            this.RATES_LIST_API_URL + '/instruction/' + id)
        .pipe(map(rates => {
            return rates;
        }));
    }    

    public getRateHistory(rateId: string, days: number): any {
        return this.http.get<ApiResponse>(
            this.RATES_LIST_API_URL + '/' + rateId + '/' + days)
        .pipe(map(rates => {
            return rates;
        }));
    }

    public groupRates(rates: any[], group: string): any {
        let results;
        let dateFormat = 'MM-DD-YYYY';
        if (group === 'monthly'){
            results = groupBy(rates, (record) => {
                return moment(record.Date).format('MMM-YY')
            });   
            results = Object.keys(results).sort((a,b) => {
                    if (moment('1-' + a, 'DD-MMM-YY') > moment('1-' + b, 'DD-MMM-YY')) return 1;
                    return -1
                }).reduce(
                    (obj, key) => { 
                    obj[key] = results[key]; 
                    return obj;
                    }, 
                    {}
              );            
        } else if (group === 'weekly'){
            dateFormat = 'MMM-DD-YY';
            results = groupBy(rates, (record) => {
                return moment(record.Date).startOf('isoWeek').format(dateFormat)
            });
            results = Object.keys(results).sort((a,b) => {
                if (moment(a, dateFormat) > moment(b, dateFormat)) return 1;
                return -1
            }).reduce(
                (obj, key) => { 
                obj[key] = results[key]; 
                return obj;
                }, 
                {}
          );                   
        } else {
            dateFormat = 'MMM-DD-YYYY';
            results = groupBy(rates, (record) => {
                return moment(record.Date).format(dateFormat)
            });
            results = Object.keys(results).sort((a,b) => {
                if (moment(a, dateFormat) > moment(b, dateFormat)) return 1;
                return -1
            }).reduce(
                (obj, key) => { 
                obj[key] = results[key]; 
                return obj;
                }, 
                {}
          );             
        }
        return results;
    }

    public buildChartData(rates: any[], group: string): any {

        const allRates = [];
        let i = -1;
        rates.map((rate) => {
            i++;
            rate.map((r) => {
                r.key = i;
                // 2. add each rate to a complete array
                allRates.push(r);
                return r
            });            
        });

        const compare = (rates.length > 1);
        const retval = {
            series: [
                {
                    name: 'High',
                    data: []
                },
                {
                    name: 'Avg',
                    data: []
                },
                {
                    name: 'Low',
                    data: []
                }       
            ],
            xaxis: {
                type: 'category',
                categories: []
            }
        }

        if (group === 'daily'){
            retval.series = [{
                name: 'Rate',
                data: []
            }]
        }

        if (compare){
            retval.series = [];
            for (let index = 0; index < rates.length; index++) {
                retval.series.push({
                    name: (index + 1).toString(),
                    data: []
                });
            }
        }

        const groups = this.groupRates(allRates, group);
        Object.entries(groups).forEach(groupResult => {
            
            // TODO: loop over all results and get all headers in case a rate doesn't have complete history
            retval.xaxis.categories.push(groupResult[0]);
            for (let index = 0; index < rates.length; index++) {
             
                let total = 0;
                let high = 0;
                let low = 0;
                let rate = 0;
                const grouping: any[any] = groupResult[1];
                let recordCount = 0;
                grouping.forEach(record => {
                    if (record.key === index){
                        recordCount++;
                        // rate = Number(record.Rate);
                        rate = (record.Rate || 0); // in case the history doesn't go back this far
                        rate = Number(rate);
                        total += rate;
                        if (rate > high) high = rate;
                        if (rate < low || low === 0) low = rate;
                    }
                });
                let avg = 0;
                if (rate > 0){
                    avg = +((total / recordCount).toFixed(4))
                }
                if (compare){
                    if (group === 'daily'){
                        retval.series[index].data.push(rate);
                    } else {
                        retval.series[index].data.push(avg);
                    }
                } else {
                    if (group === 'daily'){
                        retval.series[0].data.push(rate);
                    } else {
                        retval.series[0].data.push(high.toFixed(4));
                        retval.series[1].data.push(avg);
                        retval.series[2].data.push(low.toFixed(4));
                    }
                }
            }
        });

        return retval;

    }

    // public buildChartData(rates: any[], group: string): any {

    //     const allRates = [];
    //     let i = -1;
    //     rates.map((rate) => {
    //         i++;
    //         rate.map((r) => {
    //             r.key = i;
    //             // 2. add each rate to a complete array
    //             allRates.push(r);
    //             return r
    //         });            
    //     });

    //     const compare = (rates.length > 1);
    //     const retval = {
    //         series: [
    //             {
    //                 name: 'High',
    //                 data: []
    //             },
    //             {
    //                 name: 'Avg',
    //                 data: []
    //             },
    //             {
    //                 name: 'Low',
    //                 data: []
    //             }       
    //         ],
    //         xaxis: {
    //             type: 'category',
    //             categories: []
    //         }
    //     }

    //     if (group === 'daily'){
    //         retval.series = [{
    //             name: 'Rate',
    //             data: []
    //         }]
    //     }

    //     const groups = this.groupRates(rates[0], group);
    //     Object.entries(groups).forEach(result => {
    //         retval.xaxis.categories.push(result[0]);
    //     });

    //     Object.entries(groups).forEach(groupResult => {

    //         const rateGroup = groupBy(groupResult[1], (record) => {
    //             return record.key;
    //         });

    //         let avg = 0;
    //         let high = 0;
    //         let low = 0;
    //         let rate = 0;
    //         const grouping: any[any] = groupResult[1];
    //         grouping.forEach(record => {
    //             rate = Number(record.Rate);
    //             avg += rate;
    //             if (rate > high) high = rate;
    //             if (rate < low || low === 0) low = rate;
    //         })

    //         if (compare){
    //             if (group === 'daily'){
    //                 retval.series[0].data.push(rate);
    //             } else {
    //                 retval.series[0].data.push((avg / grouping.length).toFixed(4));
    //             }
    //         } else {
    //             if (group === 'daily'){
    //                 retval.series[0].data.push(rate);
    //             } else {
    //                 retval.series[0].data.push(high.toFixed(4));
    //                 retval.series[1].data.push((avg / grouping.length).toFixed(4));
    //                 retval.series[2].data.push(low.toFixed(4));
    //             }
    //         }
    //     });

    //     return retval;








    //     // const values = [];

    //     // Object.entries(results).forEach(result => {
    //     //     let avg = 0;
    //     //     let high = 0;
    //     //     let low = 0;
    //     //     const grouping: any[any] = result[1];
    //     //     grouping.forEach(record => {
    //     //         const rate = Number(record.Rate);
    //     //         avg += rate;
    //     //         if (rate > high) high = rate;
    //     //         if (rate < low || low === 0) low = rate;
    //     //     })
    //     //     values.push({
    //     //         'Date': result[0],
    //     //         'Rate': (avg / grouping.length).toFixed(4),
    //     //         'High': high.toFixed(4),
    //     //         'Low': low.toFixed(4)
    //     //     });
    //     // });

    //     // return values;
    // }

    // public avgRates(rates: any, days: number): any {
        
    //     // year / by month
    //     // 6 month / by week
    //     // 1 month / each day

    //     let results;
    //     if (days <= 30) return rates;
    //     if (days >= 365){
    //         results= groupBy(rates, (record) => {
    //             return moment(record.Date).format('MMM-YY')
    //         });
    //     } else {
    //         results = groupBy(rates, (record) => {
    //             return moment(record.Date).startOf('isoWeek').format('MMM-DD-YY')
    //         });
    //     }

    //     const values = [];

    //     Object.entries(results).forEach(result => {
    //         let avg = 0;
    //         const group: any[any] = result[1];
    //             group.forEach(record => {
    //             avg += Number(record.Rate);
    //         })
    //         values.push({
    //             'Date': result[0],
    //             'Rate': (avg / group.length).toFixed(4)
    //         });
    //     });

    //     return values;
    // }

}