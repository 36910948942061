import { Component, OnInit, ViewEncapsulation, Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyService } from 'app/shared/services/company.service';

@Component({
    selector     : 'company-edit',
    templateUrl  : './edit.component.html',
    styleUrls    : ['./edit.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CompanyEditComponent implements OnInit
{
    @Input() company: {
        _id: string,
        name: string,
        accountNumber: string,
        accountStatus: string,
        trialEndDate: Date,
        settings: {
            roundMonth: boolean,
            trackRateTitle: boolean
        },
        parent: string
    }

    composeForm: FormGroup;
    copyFields: { cc: boolean, bcc: boolean };
    quillModules: any;
    statusList;
    companies;

    /**
     * Constructor
     *
     * @param {MatDialogRef} matDialogRef
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<CompanyEditComponent>,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private companyService: CompanyService
    )
    {

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.statusList = ['Active', 'Inactive', 'Trial', 'Overdue'];

        // Create the form
        this.composeForm = this._formBuilder.group({
            id:                 [this.data._id],
            name:               [this.data.name, [Validators.required]],
            accountNumber:      [this.data.accountNumber, [Validators.required]],
            accountStatus:      [this.data.accountStatus, [Validators.required]],
            trialEndDate:       [this.data.trialEndDate, [Validators.required]],
            roundMonth:         [this.data.settings.roundMonth, [Validators.required]],
            trackRateTitle:     [this.data.settings.trackRateTitle, [Validators.required]],            
            parent:             [this.data.parent],
            territories:        [this.data.territories],
        });

        this.companyService.getList()
        .subscribe(
            response => {
                this.companies = response.data;                            
            },
            fail => {
            },
            () => {                
        });        
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Show the copy field with the given field name
     *
     * @param name
     */
    showCopyField(name: string): void
    {
        // Return, if the name is not one of the available names
        if ( name !== 'cc' && name !== 'bcc' )
        {
            return;
        }

        // Show the field
        this.copyFields[name] = true;
    }

    // /**
    //  * Save and close
    //  */
    // saveAndClose(): void
    // {
    //     // Save the company
    //     this.save();

    //     // Close the dialog
    //     this.close();
    // }

    /**
     * close
     */
    close(): void
    {
        // Close the dialog
        this.matDialogRef.close();
    }

    /**
     * Discard the message
     */
    discard(): void
    {

    }

    /**
     * Save the company
     */
    saveAndClose(): void
    {

        const data = this.composeForm.value;

        // clone the object before updating, in case of failure
        const company = JSON.parse(JSON.stringify(this.data));

        company.name = data.name;
        company.accountNumber = data.accountNumber;
        company.accountStatus = data.accountStatus;
        company.trialEndDate = data.trialEndDate;
        company.settings.roundMonth = data.roundMonth;
        company.settings.trackRateTitle =  data.trackRateTitle;
        company.parent = data.parent;
        company.territories = data.territories;

        this.companyService.save(company)
        .subscribe(
            response => {
                this.matDialogRef.close(company);
            },
            fail => {
                this.matDialogRef.close();
            }
        );
    }

    /**
     * Send the message
     */
    send(): void
    {

    }
}
