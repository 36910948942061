import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RateService } from './services/rate.service';
import { ReportService } from './services/report.service';
import { InstructionService } from './services/instruction.service';
import { UserService } from './services/user.service';
import { CompanyService } from './services/company.service';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatDialogModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatDialogModule
    ],
    providers: [
        RateService,
        ReportService,
        InstructionService,
        CompanyService,
        UserService
    ]
})
export class SharedModule
{
}
