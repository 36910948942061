import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '../interfaces/api-response.type';
import { map } from 'rxjs/operators';

@Injectable()
export class ReportService {

    public API_URL = '/reports';

    constructor(private http: HttpClient) {}

    public getList(): any {
        return this.http.get<ApiResponse>(
            this.API_URL + '/')
        .pipe(map(reports => {
            return reports;
        }));
    }

    public get(reportId: string): any {
        return this.http.get<ApiResponse>(
            this.API_URL + '/' + reportId)
        .pipe(map(reports => {
            return reports;
        }));
    }

    public delete(id: string): any {
        return this.http.delete<ApiResponse>(
            this.API_URL + '/' + id)
        .pipe(map(response => {
            return response;
        }));
    }
        
    public save(report: any): any {
        return this.http.put<ApiResponse>(
            this.API_URL + '/' + report._id, report)
        .pipe(map(reports => {
            return reports;
        }));
    }

    public saveAs(name: string, state: any): any {
        return this.http.post<ApiResponse>(
            this.API_URL, {name, state})
        .pipe(map(reports => {
            return reports;
        }));
    }

}
