import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { TreoNavigationItem } from '@treo/components/navigation';
import { TreoMockApi } from '@treo/lib/mock-api/mock-api.interfaces';
import { TreoMockApiService } from '@treo/lib/mock-api/mock-api.service';
import { compactNavigation, defaultNavigation, futuristicNavigation, horizontalNavigation } from 'app/data/mock/common/navigation/data';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class NavigationMockApi implements TreoMockApi
{
    // Private Readonly
    private readonly _compactNavigation: TreoNavigationItem[];
    private readonly _defaultNavigation: TreoNavigationItem[];
    private readonly _futuristicNavigation: TreoNavigationItem[];
    private readonly _horizontalNavigation: TreoNavigationItem[];

    /**
     * Constructor
     *
     * @param _treoMockApiService
     */
    constructor(
        private _treoMockApiService: TreoMockApiService,
        private _authService: AuthService
    )
    {
        // Set the data
        this._compactNavigation = compactNavigation;
        this._defaultNavigation = defaultNavigation;
        this._futuristicNavigation = futuristicNavigation;
        this._horizontalNavigation = horizontalNavigation;

        
        if (_authService.hasAuthority(['admin']))
        {

            defaultNavigation.push(
                {
                    id      : 'settings',
                    title   : 'Settings',
                    subtitle: 'Edit',
                    type    : 'group',
                    icon    : 'apps',
                    children: [
                        {
                            id   : 'instructions',
                            title: 'Instructions',
                            type : 'basic',
                            link : '/app/settings/instructions',
                            icon : 'heroicons:clipboard-list',
                        }
                    ]
                }
            );

            defaultNavigation.push(
                {
                    id      : 'admin',
                    title   : 'Admin',
                    subtitle: 'Edit',
                    type    : 'group',
                    icon    : 'apps',
                    children: [
                        {
                            id   : 'companies',
                            title: 'Companies',
                            type : 'basic',
                            link : '/app/admin/companies',
                            icon : 'heroicons:office-building',
                        },
                        {
                            id   : 'users',
                            title: 'Users',
                            type : 'basic',
                            link : '/app/admin/users',
                            icon : 'heroicons:users',
                        }
                    ]
                }
            );
        } 

        if (_authService.hasAuthority(['manager']))
        {
            defaultNavigation.push(
                {
                    id      : 'settings',
                    title   : 'Settings',
                    subtitle: 'Edit',
                    type    : 'group',
                    icon    : 'apps',
                    children: [
                        {
                            id   : 'instructions',
                            title: 'Instructions',
                            type : 'basic',
                            link : '/app/settings/instructions',
                            icon : 'heroicons:clipboard-list',
                        }
                    ]
                }
            );
                            
            // TODO: enable this once the users module supports admin and manager features
            //     defaultNavigation.push(
            //         {
            //             id      : 'admin',
            //             title   : 'Admin',
            //             subtitle: 'Edit',
            //             type    : 'group',
            //             icon    : 'apps',
            //             children: [
            //                 {
            //                     id   : 'users',
            //                     title: 'Users',
            //                     type : 'basic',
            //                     link : '/app/admin/users',
            //                     icon : 'heroicons:users',
            //                 }
            //             ]
            //         }
            //     );
        } 

        // Register the API endpoints
        this.register();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register
     */
    register(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        this._treoMockApiService
            .onGet('api/common/navigation')
            .reply(() => {

                // Fill compact navigation children using the default navigation
                this._compactNavigation.forEach((compactNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === compactNavItem.id )
                        {
                            compactNavItem.children = _.cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill compact navigation children using the default navigation
                this._futuristicNavigation.forEach((futuristicNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === futuristicNavItem.id )
                        {
                            futuristicNavItem.children = _.cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill horizontal navigation children using the default navigation
                this._horizontalNavigation.forEach((horizontalNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === horizontalNavItem.id )
                        {
                            horizontalNavItem.children = _.cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                return [
                    200,
                    {
                        compact   : _.cloneDeep(this._compactNavigation),
                        default   : _.cloneDeep(this._defaultNavigation),
                        futuristic: _.cloneDeep(this._futuristicNavigation),
                        horizontal: _.cloneDeep(this._horizontalNavigation)
                    }
                ];
            });
    }
}
