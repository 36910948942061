import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '../interfaces/api-response.type';
import { map } from 'rxjs/operators';

@Injectable()
export class UserService {

    public API_URL = '/users';

    constructor(private http: HttpClient) {}

    public getList(): any {
        let params = new HttpParams();
        // params = params.append('page', page.toString());
        // params = params.append('limit', limit.toString());
        // params = params.append('admin', admin.toString());
        // params = params.append('search', search); 
        params = params.append('page', '1');
        params = params.append('limit', '100');
        params = params.append('search', '');                
        return this.http.get<ApiResponse>(
            this.API_URL + '/', {params})
        .pipe(map(response => {
            return response;
        }));
    }

    public get(id: string): any {
        return this.http.get<ApiResponse>(
            this.API_URL + '/' + id)
        .pipe(map(response => {
            return response;
        }));
    }

    public save(data: any): any {
        return this.http.put<ApiResponse>(
            this.API_URL + '/' + data._id, data)
        .pipe(map(response => {
            return response;
        }));
    }
    
    public delete(id: string): any {
        return this.http.delete<ApiResponse>(
            this.API_URL + '/' + id)
        .pipe(map(response => {
            return response;
        }));
    }

    public saveAs(data: any): any {
        return this.http.post<ApiResponse>(
            this.API_URL, data)
        .pipe(map(response => {
            return response;
        }));
    }

}
