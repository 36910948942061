import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthUtils } from 'app/core/auth/auth.utils';

@Injectable()
export class AuthService
{
    // Private
    private _authenticated: boolean;
    public resellerAccount = {
        id: 'erp',
        name: 'EnergyRateReport.com',
        url: 'www.energyratereport.com',
        logo: 'assets/images/logo/logo.png'
    };
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        @Inject(DOCUMENT) private document: any,
    )
    {
        // Set the defaults
        this._authenticated = false;

        this.setResellerAccount(document.location.hostname);

    }

    setResellerAccount(domain: string) {
        if (domain.indexOf('truelightenergy') > -1) {
            this.resellerAccount = {
                id: 'tle',
                name: 'True Light Energy',
                url: 'www.truelightenergy.com',
                logo: 'assets/images/logo/truelight-logo.png'
            }
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter and getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('access_token', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('access_token');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign Up
     *
     * @param credentials
     */ 
    signUp(credentials: { name: string, email: string, password: string, company: string, agreements: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post('auth/signup', credentials).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.token;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Return a new observable with the response
                return of(response);
            })
        );
    }
    
    /**
     * Reset Password
     *
     * @param credentials
     */
    resetPassword(credentials: { password: string, passwordConfirm: string, token: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post('auth/reset', credentials).pipe(
            switchMap((response: any) => {

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Forgot Password
     *
     * @param credentials
     */
    forgotPassword(credentials: { username: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post('auth/forgot', credentials).pipe(
            switchMap((response: any) => {

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { username: string, password: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post('auth/signin', credentials).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.token;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // Renew token
        return this._httpClient.post('auth/verify', {
            access_token: this.accessToken
        }).pipe(
            catchError(() => {

                // Return false
                return of(false);
            }),
            switchMap((response: any) => {

                // Store the access token in the local storage
                // TODO: update the end point to extend the token
                // this.accessToken = response.token;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('access_token');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    hasAuthority(authorities: string[]): boolean
    {
        if (!authorities || authorities.length < 0 ) return true;
        const roles = AuthUtils.roles;
        if (!roles) return false;
        const found = authorities.some(r=> roles.indexOf(r) >= 0)
        return found;
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}
